import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Link from "components/Link";
import MainCard from "components/MainCard";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useGetModelQuery, Vehicle } from "service/api-slice";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";
import EditVehicle from "../EditVehicle";

export const VehicleInfoContainer = ({ vehicle }: { vehicle: Vehicle }) => {
  const { module } = useModuleContext();
  const [editMode, setEditMode] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const done = () => {
    setEditMode(false);
  };

  return (
    <>
      {module === Module.SiteOperator && (
        <Dialog open={editMode} fullScreen={fullScreen}>
          <DialogTitle>
            <FormattedMessage id="edit-vehicle" />
          </DialogTitle>
          <DialogContent sx={{ p: 0, pb: 2 }}>
            <EditVehicle vehicle={vehicle} done={done} />
          </DialogContent>
        </Dialog>
      )}
      <MainCard
        content={false}
        title={<FormattedMessage id="vehicle-details" />}
        secondary={
          module === Module.SiteOperator && (
            <Button
              onClick={() => {
                setEditMode(true);
              }}
            >
              <FormattedMessage id="edit-vehicle" />
            </Button>
          )
        }
      >
        <VehicleSummary vehicle={vehicle} />
      </MainCard>
    </>
  );
};

const VehicleSummary = ({ vehicle }: { vehicle: Vehicle }) => {
  const { data: model } = useGetModelQuery(vehicle.model_id ?? "", {
    skip: !vehicle.model_id,
  });

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow hover={false}>
            <TableCell>
              <FormattedMessage id="description" />
            </TableCell>
            <TableCell>
              {vehicle.make_id && vehicle.model_id && model?.data.vpic_id ? (
                <Link
                  to={`/charging-sessions?makeId=${vehicle.make_id}&vpicId=${model.data.vpic_id}&modelId=${vehicle.model_id}`}
                >
                  {vehicle.description}
                </Link>
              ) : (
                vehicle.description
              )}
            </TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <FormattedMessage id="color" />
            </TableCell>
            <TableCell>{vehicle.color}</TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <FormattedMessage id="license-plate" />
            </TableCell>
            <TableCell>
              {vehicle.license_plate_state} {vehicle.license_plate}
            </TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <FormattedMessage id="owner" />
            </TableCell>
            <TableCell>{vehicle.owner?.display_name}</TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <FormattedMessage id="vin" />
            </TableCell>
            <TableCell>{vehicle.vin}</TableCell>
          </TableRow>
          <TableRow hover={false}>
            <TableCell>
              <FormattedMessage id="mac-address" />
            </TableCell>
            <TableCell>{vehicle.mac_address}</TableCell>
          </TableRow>
          {vehicle.fleet_id && (
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="fleet" />
              </TableCell>
              <TableCell>{vehicle.fleet_id}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
