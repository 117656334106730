import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import MainCard from "components/MainCard";
import { formatDuration } from "date-fns";
import SingleMeterValue from "modules/site-operator/charging-stations/components/SingleMeterValue";
import VehicleDetails from "modules/site-operator/charging-stations/components/VehicleDetails";
import { FormattedMessage } from "react-intl";
import { Transaction } from "service/api-slice";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import UploadEmployeeVerificationImage from "./UploadEmployeeVerificationImage";
import ErrorCodeCard from "components/ErrorCodeCard";

export const TransactionSummaryTables = ({
  txn,
  refetch,
  chargingTime,
}: {
  txn?: Transaction;
  refetch?: () => void;
  chargingTime: Duration | undefined;
}) => {
  const { module } = useModuleContext();

  const gridItemSize = module === Module.SiteOperator ? 4 : 6;

  return (
    <>
      <MainCard title={<FormattedMessage id="transaction-summary" />} content={false}>
        {txn && (
          <>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} md={gridItemSize} lg={gridItemSize}>
                <SingleMeterValue
                  title="charging-time"
                  value={{
                    value: chargingTime
                      ? formatDuration(chargingTime, { format: ["hours", "minutes"] })
                      : "-",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={gridItemSize} lg={gridItemSize}>
                <SingleMeterValue
                  title="energy-used"
                  value={{
                    value: `${(txn.meter_end - txn.meter_start) / 1000} kWh`,
                  }}
                />
              </Grid>
              {module === Module.SiteOperator && (
                <Grid item xs={12} md={gridItemSize} lg={gridItemSize}>
                  <SingleMeterValue
                    title="total-cost"
                    value={{
                      value: `$${(((txn.meter_end - txn.meter_start) / 1000) * 0.59).toFixed(2)}`,
                      caption: `rate of 59¢/kWh`,
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <TableContainer sx={{ mt: 2 }}>
              <Table size="small">
                <TableBody>
                  {module === Module.SiteOperator && (
                    <TableRow hover={false} key="charging-station">
                      <TableCell>
                        <FormattedMessage id="charging-station" />
                      </TableCell>
                      <TableCell>{txn.charging_station.name}</TableCell>
                    </TableRow>
                  )}
                  <TableRow hover={false} key="start-time">
                    <TableCell>
                      <FormattedMessage id="start-time" />
                    </TableCell>
                    <TableCell>{parseAndFormatDate(txn.start_time)}</TableCell>
                  </TableRow>
                  <TableRow hover={false} key="end-time">
                    <TableCell>
                      <FormattedMessage id="end-time" />
                    </TableCell>
                    <TableCell>{txn.end_time && parseAndFormatDate(txn.end_time)}</TableCell>
                  </TableRow>
                  <TableRow hover={false} key="meter-start">
                    <TableCell>
                      <FormattedMessage id="meter-start" />
                    </TableCell>
                    <TableCell>{txn.meter_start} Wh</TableCell>
                  </TableRow>
                  <TableRow hover={false} key="meter-end">
                    <TableCell>
                      <FormattedMessage id="meter-end" />
                    </TableCell>
                    <TableCell>{txn.meter_end} Wh</TableCell>
                  </TableRow>
                  {module === Module.SiteOperator && (
                    <TableRow hover={false} key="end-reason">
                      <TableCell>
                        <FormattedMessage id="end-reason" />
                      </TableCell>
                      <TableCell>{txn.end_reason}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {!txn && (
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow hover={false}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow hover={false}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </MainCard>
      {txn?.vendor_stopped_reason && <ErrorCodeCard error={txn.vendor_stopped_reason} />}
      {txn && (
        <>
          <MainCard sx={{ mt: 2 }} title={<FormattedMessage id="vehicle" />} content={false}>
            <VehicleDetails
              vehicle={txn.vehicle}
              chargingStationId={txn.charging_station.id}
              transactionId={txn.id}
              onUploaded={() => {
                if (txn.vehicle?.id && refetch) {
                  refetch();
                }
              }}
            />
          </MainCard>
          <MainCard
            sx={{ mt: 2 }}
            title={<FormattedMessage id="employee-badge-photo" />}
            content={false}
          >
            <UploadEmployeeVerificationImage
              csId={txn.charging_station.id}
              txId={txn.id}
              vehicleId={txn.vehicle?.id}
            />
          </MainCard>
        </>
      )}
    </>
  );
};
