import {
  ApiOutlined,
  BoxPlotOutlined,
  CarFilled,
  ClockCircleOutlined,
  DashboardOutlined,
  DollarOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Alert, Box, Grid, Link, Stack, useMediaQuery, useTheme } from "@mui/material";
import MainCard from "components/MainCard";
import { WebsocketContext } from "contexts/WebsocketContext";
import { formatDistanceToNowStrict } from "date-fns";
import UploadEmployeeVerificationImage from "pages/transactions/components/UploadEmployeeVerificationImage";
import { useCallback, useContext, useEffect, useState } from "react";
import { formatValue } from "react-currency-input-field";
import { FormattedMessage } from "react-intl";
import { ChargingStation, Transaction, useLazyGetVehicleQuery } from "service/api-slice";
import { Message, MeterValuesMessage } from "service/monitor/msg/Message";
import { ChargeCurve } from "./ChargeCurve";
import { DemandVsActualChart } from "./DemandChart";
import { PowerLimitControl } from "./PowerLimitControl";
import SingleMeterValue, { Value } from "./SingleMeterValue";
import StopTransactionButton from "./StopTransactionButton";
import { TemperatureChart } from "./TemperatureChart";
import UnlockConnectorButton from "./UnlockConnectorButton";
import VehicleDetails from "./VehicleDetails";
import VehicleName from "./VehicleName";
import { getAuthorization, isPreauthEnabled } from "components/payments/preauthorization";
import { useModuleContext } from "types/context";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";

const CurrentTransactionComponent = ({ cs, tx }: { cs: ChargingStation; tx: Transaction }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const wsContext = useContext(WebsocketContext);
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const [lastUpdatedText, setLastUpdateText] = useState<string>();
  const [power, setPower] = useState<Value>({});
  const [voltage, setVoltage] = useState<Value>({});
  const [current, setCurrent] = useState<Value>({});
  const [soc, setSoc] = useState<Value>({});
  const [pinTemps, setPinTemps] = useState<Value>({});
  const [coolantTemps, setCooolantTemps] = useState<Value>({});
  const [priceInCents, setPriceInCents] = useState<Value>({});

  const [vehicle, setVehicle] = useState<Transaction["vehicle"]>(tx.vehicle);
  const [refetchVehicleData, { data }] = useLazyGetVehicleQuery();

  useEffect(() => {
    if (data) {
      setVehicle(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (wsContext.message) {
      var msg = wsContext.message as Message<any>;
      switch (msg.type) {
        case "MeterValues":
          processMeterValues(msg as MeterValuesMessage);
          break;
      }
    }

    function processMeterValues(msg: MeterValuesMessage) {
      setLastUpdate(new Date(msg.timestamp));
      msg.message.forEach((v) => {
        if (!v.value) return;
        switch (v.measurand) {
          case "Power.Active.Import":
            setPower((prev) => ({ ...prev, value: `${v.value > 0 ? v.value / 1000 : 0} kW` }));
            break;
          case "Voltage":
            setVoltage((prev) => ({ ...prev, value: `${v.value} V` }));
            break;
          case "Voltage.Demand":
            setVoltage((prev) => ({ ...prev, caption: `Demand ${v.value} V` }));
            break;
          case "Current.Import":
            setCurrent((prev) => ({ ...prev, value: `${v.value} A` }));
            break;
          case "Current.Demand":
            setCurrent((prev) => ({ ...prev, caption: `Demand ${v.value} A` }));
            break;
          case "Temperature.P":
            if (v.location === "Outlet") {
              setPinTemps((prev) => ({
                ...prev,
                dataItems: { ...prev.dataItems, Positive: `${v.value}ºC` },
              }));
            }
            break;
          case "Temperature.N":
            if (v.location === "Outlet") {
              setPinTemps((prev) => ({
                ...prev,
                dataItems: { ...prev.dataItems, Negative: `${v.value}ºC` },
              }));
            }
            break;
          case "Temperature.Coolant.Max":
            setCooolantTemps((prev) => ({
              ...prev,
              dataItems: { ...prev.dataItems, Max: `${v.value}ºC` },
            }));
            break;
          case "Temperature.Coolant.Min":
            setCooolantTemps((prev) => ({
              ...prev,
              dataItems: { ...prev.dataItems, Min: `${v.value}ºC` },
            }));
            break;
          case "SoC":
            setSoc((prev) => ({ ...prev, value: `${v.value}%` }));
            break;
          case "SoC.TimeRemaining":
            setSoc((prev) => ({ ...prev, caption: `${v.value} min remaining` }));
            break;
          case "PriceInCents": {
            const value = formatValue({
              value: (v.value / 100).toString(),
              decimalSeparator: ".",
              decimalScale: 2,
              prefix: "$",
            });
            setPriceInCents((prev) => ({ ...prev, value: `${value}` }));
            break;
          }
        }
      });
    }
  }, [wsContext.message]);

  useEffect(() => {
    const auth = getAuthorization(cs);
    if (auth?.stop_conditions?.cost_in_cents) {
      const amt = formatValue({
        value: (auth.stop_conditions.cost_in_cents / 100).toString(),
        decimalSeparator: ".",
        decimalScale: 2,
        prefix: "$",
      });
      setPriceInCents((prev) => ({
        ...prev,
        dataItems: { ...prev.dataItems, Authorized: `${amt}` },
      }));
    }
    if (auth?.stop_conditions?.state_of_charge) {
      const SoC = auth.stop_conditions.state_of_charge;
      setSoc((prev) => ({
        ...prev,
        dataItems: { ...prev.dataItems, Target: `${SoC}%` },
      }));
    }
  }, [cs, setPriceInCents, setSoc]);

  useEffect(() => {
    function refreshLastUpdated() {
      if (lastUpdate) {
        setLastUpdateText(`updated ${formatDistanceToNowStrict(lastUpdate, { addSuffix: true })}`);
      }
    }
    refreshLastUpdated();
    const interval = setInterval(() => refreshLastUpdated(), 1000);
    return () => clearInterval(interval);
  }, [lastUpdate]);

  return (
    <MainCard title={<CurrentTransactionHeader cs={cs} tx={tx} />} darkTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Alert
            severity={wsContext.isConnected ? "success" : "error"}
            className="ws-connection-state"
          >
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Box>
                {wsContext.isConnected ? (
                  <FormattedMessage id="connected-to-server" />
                ) : (
                  <FormattedMessage id="not-connected-to-server-retrying" />
                )}
              </Box>
              <Box>{lastUpdatedText}</Box>
            </Stack>
          </Alert>
        </Grid>
        <Grid item xs={4} md={1.71} lg={1.71}>
          <SingleMeterValue icon={<ApiOutlined />} title="power" value={power} />
        </Grid>
        <Grid item xs={4} md={1.71} lg={1.71}>
          <SingleMeterValue icon={<ClockCircleOutlined />} title="soc" value={soc} />
        </Grid>
        <Grid item xs={4} md={1.71} lg={1.71}>
          <SingleMeterValue icon={<DollarOutlined />} title="price" value={priceInCents} />
        </Grid>
        <Grid item xs={6} md={1.71} lg={1.71}>
          <SingleMeterValue icon={<ThunderboltOutlined />} title="voltage" value={voltage} />
        </Grid>
        <Grid item xs={6} md={1.71} lg={1.71}>
          <SingleMeterValue icon={<BoxPlotOutlined />} title="current" value={current} />
        </Grid>
        <Grid item xs={6} md={1.71} lg={1.71}>
          <SingleMeterValue icon={<DashboardOutlined />} title="pin_temp" value={pinTemps} />
        </Grid>
        <Grid item xs={6} md={1.71} lg={1.71}>
          <SingleMeterValue
            icon={<DashboardOutlined />}
            title="coolant_temp"
            value={coolantTemps}
          />
        </Grid>
        {!mobile && (
          <>
            <Grid item xs={12} md={4} lg={4}>
              <ChargeCurve chargingStationId={cs.id} transactionId={tx.id} />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <DemandVsActualChart chargingStationId={cs.id} transactionId={tx.id} />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TemperatureChart chargingStationId={cs.id} transactionId={tx.id} />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6} lg={6}>
          <MainCard title="Vehicle" content={false}>
            <VehicleDetails
              vehicle={vehicle}
              chargingStationId={cs.id}
              transactionId={tx.id}
              onUploaded={() => {
                if (vehicle?.id) {
                  refetchVehicleData(vehicle.id);
                }
              }}
            />
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MainCard title={<FormattedMessage id="employee-badge-photo" />} content={false}>
            <UploadEmployeeVerificationImage
              authorizationId={getAuthorization(cs)?.id}
              csId={cs.id}
              txId={isPreauthEnabled(cs) ? undefined : tx.id}
              vehicleId={vehicle?.id || undefined}
            />
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <PowerLimitControl chargingStationId={cs.id} transactionId={tx.id} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Stack direction="column" spacing={2}>
            <StopTransactionButton chargingStationId={cs.id} transactionId={tx.id} />
            <UnlockConnectorButton chargingStationId={cs.id} />
          </Stack>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default CurrentTransactionComponent;

const CurrentTransactionHeader = ({ cs, tx }: { cs: ChargingStation; tx: Transaction }) => {
  const { module } = useModuleContext();
  const navigate = useNavigateWithModule(module);
  const navigateToChargingStation = useCallback(() => {
    navigate(`charging-stations/${cs.id}`);
  }, [cs, navigate]);
  return (
    <Stack direction="column">
      <Link onClick={navigateToChargingStation}>{cs.name}</Link>
      <Stack direction="row" alignItems="center" spacing={1}>
        <CarFilled />
        <VehicleName vehicle={tx.vehicle} />
      </Stack>
    </Stack>
  );
};
