import { BugOutlined, DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  Chip,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import JsonViewer from "components/JsonViewer";
import TableNoResults from "components/TableNoResults";
import TablePagination from "components/pagination/table-pagination";
import { formatRFC3339 } from "date-fns";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { TransactionsTable } from "pages/transactions/components/TransactionsTable";
import { ChangeEvent, Fragment, useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  ChargingStation,
  ChargingStationAuthorization,
  GetAuthorizationsArg,
  Invoice,
  Transaction,
  TransactionStopCondition,
  useGetAuthorizationsQuery,
} from "service/api-slice";
import { useModuleContext } from "types/context";
import { formatCents } from "utils/currency";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";

const AuthStatusChip = ({ auth }: { auth: ChargingStationAuthorization }) => {
  if (!auth) {
    return <Chip size="small" color="info" label="No Authorization" />;
  }
  let color: "info" | "error" | "warning" | "success" = "error";
  let label: string = "Unexpected";

  switch (auth.type) {
    case "None":
      color = "info";
      label = "No Authorization";
      break;
    case "Fleet":
      color = "success";
      label = "Fleet";
      break;
    case "CreditCardPreauth":
      if (!auth.invoice) {
        color = "error";
        label = "CC No Invoice";
        break;
      }
      switch (auth.status) {
        case "Pending":
          color = "warning";
          label = "CC Authorizing...";
          break;
        case "Authorized":
          color = "success";
          label = "CC Authorized";
          break;
        case "Finalizing":
          color = "warning";
          label = `CC Collecting ${formatCents(auth.invoice.total)}`;
          break;
        case "Finalized": {
          const total = auth.invoice.total;
          if (auth.invoice.status === "paid") {
            const collected = auth.invoice.collected ?? 0;
            if (collected === total) {
              color = "success";
              label = `CC Paid ${formatCents(collected)}`;
            } else {
              color = "warning";
              label = `CC Paid ${formatCents(collected)} / ${formatCents(total)}`;
            }
          } else {
            color = "warning";
            label = `CC Collecting ${formatCents(total)}`;
          }
        }
      }
  }
  return <Chip size="small" variant="outlined" color={color} label={label} />;
};

const RenderSummary = ({ auth }: { auth: ChargingStationAuthorization }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Grid item xs={12}>
          <AuthStatusChip auth={auth} />
        </Grid>
        <Grid item xs={12}>
          {/* TODO: Hook up vehicle here instead of MAC */}
          {!!auth.mac_address && `MAC: ${auth.mac_address}`}
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid item xs={12}>
          {!!auth.stop_conditions?.cost_in_cents && (
            <Chip
              size="small"
              color={stopReasonIncludes(auth, "Cost") ? "success" : "default"}
              label={formatCents(auth.stop_conditions.cost_in_cents)}
            />
          )}
          {!!auth.stop_conditions?.state_of_charge && (
            <Chip
              size="small"
              color={stopReasonIncludes(auth, "StateOfCharge") ? "success" : "default"}
              label={`${auth.stop_conditions.state_of_charge}%`}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {stopReasonIncludes(auth, "IdleTimeOut") && (
            <Chip size="small" variant="outlined" color="error" label="Idle Timeout" />
          )}
          {stopReasonIncludes(auth, "Attendant") && (
            <Chip size="small" variant="outlined" color="warning" label="Cancel Button" />
          )}
          {stopReasonIncludes(auth, "MACChanged") && (
            <Chip size="small" variant="outlined" color="warning" label="Vehicle Changed" />
          )}
          {stopReasonIncludes(auth, "ConfigChange") && (
            <Chip size="small" variant="outlined" color="warning" label="Config Change" />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const columnHelper = createColumnHelper<ChargingStationAuthorization>();
const columns = [
  columnHelper.display({
    id: "expander",
    header: () => null,
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <IconButton aria-label="expand row" size="small" onClick={row.getToggleExpandedHandler()}>
          {row.getIsExpanded() ? <DownOutlined /> : <RightOutlined />}
        </IconButton>
      ) : (
        <></>
      );
    },
  }),
  columnHelper.display({
    id: "summary",
    cell: ({ row }) => <RenderSummary auth={row.original} />,
  }),
  columnHelper.display({
    id: "debug",
    header: () => null,
    cell: ({ row }) => <BugOutlined />,
  }),
];

const debugColumns = [
  columnHelper.display({
    id: "expander",
    header: () => null,
    cell: ({ row }) => {
      return row.getCanExpand() ? (
        <IconButton aria-label="expand row" size="small" onClick={row.getToggleExpandedHandler()}>
          {row.getIsExpanded() ? <DownOutlined /> : <RightOutlined />}
        </IconButton>
      ) : (
        <></>
      );
    },
  }),
  columnHelper.accessor("invoice", {
    cell: (i) => <RenderInvoice invoice={i.getValue()} />,
    header: () => <FormattedMessage id="total" />,
  }),
  columnHelper.accessor("status", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="status" />,
  }),
  columnHelper.accessor("type", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="type" />,
  }),
  columnHelper.accessor("mac_address", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="mac-address" />,
  }),
  columnHelper.accessor("valid_until", {
    cell: (i) => formatValidUntil(i.getValue()),
    header: () => <FormattedMessage id="valid-until" />,
  }),
  columnHelper.accessor("stop_conditions", {
    cell: (i) => <RenderStopCondition stop_conditions={i.getValue()} />,
    header: () => <FormattedMessage id="stop-conditions" />,
  }),
  columnHelper.accessor("finalize_reasons", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="finalize-reasons" />,
  }),
  columnHelper.display({
    id: "debug",
    header: () => null,
    cell: ({ row }) => <BugOutlined />,
  }),
];

const formatValidUntil = (valid_until?: string) => {
  if (valid_until) {
    return parseAndFormatDate(valid_until);
  }
  return "NULL";
};

const RenderStopCondition = ({
  stop_conditions,
}: {
  stop_conditions?: TransactionStopCondition;
}) => {
  return (
    <List>
      {stop_conditions?.cost_in_cents && (
        <ListItem>{formatCents(stop_conditions.cost_in_cents)}</ListItem>
      )}
      {stop_conditions?.state_of_charge && <ListItem>{stop_conditions.state_of_charge}%</ListItem>}
    </List>
  );
};

const RenderInvoice = ({ invoice }: { invoice?: Invoice }) => {
  if (!invoice) {
    return <></>;
  }
  const collected = invoice.collected ?? 0;
  const total = invoice.total;
  let color: "info" | "success" = "info";
  if (invoice.status === "paid") {
    if (collected === total) {
      color = "success";
    }
  }

  return (
    <Chip color={color} size="small" label={`${formatCents(collected)} / ${formatCents(total)}`} />
  );
};

const stopReasonIncludes = (auth: ChargingStationAuthorization, reason: string) => {
  return auth.finalize_reasons?.includes(reason) ?? false;
};

const stringifyRange = (range: { start: Date; end: Date }) => {
  return {
    start: formatRFC3339(range.start),
    end: formatRFC3339(range.end),
  };
};

export const AuthorizationsTable = ({
  cs,
  range,
}: {
  cs: ChargingStation;
  range: { start: Date; end: Date };
}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const [queryArgs, setQueryArgs] = useState<GetAuthorizationsArg>({
    csId: cs.id,
    count: pageSize,
    page: pageIndex,
    range: stringifyRange(range),
  });

  const [rawAuthorization, setRawAuthorization] = useState<ChargingStationAuthorization>();
  const [displayDebugColumns, setDisplayDebugColumns] = useState(false);
  const toggleDisplayDebugColumns = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDisplayDebugColumns(e.target.checked);
  }, []);

  const { data, isLoading, isError } = useGetAuthorizationsQuery(queryArgs, {
    pollingInterval: 60_000,
  });

  const pagination = useMemo(() => {
    setQueryArgs({
      page: pageIndex,
      count: pageSize,
      csId: cs.id,
      range: stringifyRange(range),
    });
    return {
      pageIndex,
      pageSize,
    };
  }, [cs, pageIndex, pageSize, range]);

  const table = useReactTable({
    data: data?.data ?? [],
    columns: displayDebugColumns ? debugColumns : columns,
    pageCount: data?.meta.pagination.page_count ?? -1,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: (row) => {
      return row.original.type !== "None";
    },
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { module } = useModuleContext();
  const navigate = useNavigateWithModule(module);

  return (
    <Fragment>
      <Dialog open={!!rawAuthorization} onClose={() => setRawAuthorization(undefined)}>
        <DialogTitle>Raw Authorization</DialogTitle>
        <JsonViewer json={rawAuthorization} />
      </Dialog>
      <TableContainer>
        <Table aria-label="Charging Station Authorizations Table">
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {(isLoading || isError) &&
              table.getHeaderGroups().map((headerGroup) => {
                return [...Array(10).keys()].map((i) => (
                  <TableRow key={i}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                          <Skeleton />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ));
              })}
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <TableRow hover={true}>
                    {row.getVisibleCells().map((cell) => {
                      if (cell.column.id === "debug") {
                        return (
                          <TableCell
                            key={cell.id}
                            onClick={() => {
                              setRawAuthorization(cell.row.original);
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={cell.id}
                          onClick={() => {
                            console.debug(cell.getContext());
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {row.getIsExpanded() && (
                    <TableRow>
                      <TableCell sx={{ p: 0 }} colSpan={row.getVisibleCells().length}>
                        <TransactionsTable
                          chargingStationId={cs.id}
                          authorizationId={row.original.id}
                          startTime={range.start}
                          endTime={range.end}
                          onClick={function (transaction: Transaction): void {
                            navigate(`charging-stations/${cs.id}/transactions/${transaction.id}`);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
            {table.getRowModel().rows.length === 0 && (
              <TableNoResults columnCount={table.getAllLeafColumns().length} />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
                <TablePagination
                  gotoPage={table.setPageIndex}
                  totalPages={table.getPageCount()}
                  setPageSize={table.setPageSize}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <FormControlLabel
        control={<Switch checked={displayDebugColumns} onChange={toggleDisplayDebugColumns} />}
        label={<FormattedMessage id="enable-debug" />}
      />
    </Fragment>
  );
};
