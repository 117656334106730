import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Slider,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  ChargingStation,
  useCancelChargingStationPreauthMutation,
  useFleetAuthorizeChargingStationMutation,
  usePreauthChargingStationMutation,
  useSetPreauthEnabledMutation,
} from "service/api-slice";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { SubmitToReaderButton, useCardReader } from "components/payments/card-reader";
import {
  PaymentStatus,
  TestOnlyPresentPaymentButtons,
  usePaymentIntentSyncer,
} from "components/payments/payment-intent";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import { openSnackbarError } from "store/reducers/snackbar";
import { useDispatch } from "react-redux";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import StartTransactionButton from "modules/site-operator/charging-stations/components/StartTransactionButton";
import isValidEmail from "utils/email";
import FleetSelector from "components/FleetSelector";

const socSliderProps = {
  step: 10,
  min: 50,
  max: 100,
  marks: [
    { value: 50, label: "50%" },
    { value: 60 },
    { value: 70 },
    { value: 80, label: "80%" },
    { value: 90 },
    { value: 100, label: "100%" },
  ],
};

const PreauthorizeCreditCardForm = ({
  cs,
  refetch,
}: {
  cs: ChargingStation;
  refetch: () => void;
}) => {
  const cardReader = useCardReader();
  const piSyncer = usePaymentIntentSyncer("requires_capture", refetch);

  const [amount, setAmount] = useState<number>(40);
  const [soc, setSOC] = useState<number>(100);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);

  const [preauthChargingStation, preauthChargingStationResult] =
    usePreauthChargingStationMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (preauthChargingStationResult.isSuccess) {
      piSyncer.setPaymentIntent(preauthChargingStationResult.data.data.payment_intent);
      cardReader.startPaymentIntent(preauthChargingStationResult.data.data.payment_intent);
      preauthChargingStationResult.reset();
      refetch();
    } else if (preauthChargingStationResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to preauthorize",
          message: JSON.stringify(preauthChargingStationResult.error),
        })
      );
      preauthChargingStationResult.reset();
      refetch();
    }
  }, [cardReader, dispatch, preauthChargingStationResult, piSyncer, refetch]);

  const onPreauthClick = useCallback(
    (readerId: string) => {
      if (amount < 1) {
        dispatch(
          openSnackbarError({
            title: "Failed to preauthorize",
            message: "Amount must be at least $1.00",
          })
        );
        return;
      }
      preauthChargingStation({
        csId: cs.id,
        amount_in_cents: amount * 100,
        state_of_charge: soc,

        email_receipt_to: email === "" ? undefined : email,
        reader_id: readerId,
      });
      cardReader.startOp();
    },
    [amount, cardReader, cs, dispatch, email, preauthChargingStation, soc]
  );

  const onButtonAmountChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newAmount: number) => {
      setAmount(newAmount);
    },
    [setAmount]
  );

  const onAmountChange = useCallback(
    (value?: string, name?: string, values?: CurrencyInputOnChangeValues) => {
      if (values?.float) {
        setAmount(values.float);
      } else {
        setAmount(0);
      }
    },
    [setAmount]
  );

  const onSOCSliderChange = useCallback(
    (_, value) => {
      setSOC(value as number);
    },
    [setSOC]
  );

  const onEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const email = e.target.value;
      setEmailError(email !== "" && !isValidEmail(email));
      setEmail(email);
    },
    [setEmail, setEmailError]
  );

  const [cancel, cancelResult] = useCancelChargingStationPreauthMutation();
  useEffect(() => {
    if (cancelResult.isSuccess || cancelResult.isError) {
      refetch();
    }
  }, [cancelResult, refetch]);

  const onCancelClick = useCallback(() => {
    cardReader.reset();
    piSyncer.reset();
    cancel(cs.id);
  }, [cancel, cardReader, cs, piSyncer]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InputLabel htmlFor="amount">
          <FormattedMessage id="amount" />
        </InputLabel>
      </Grid>
      <Grid container item xs={8}>
        <Grid item xs={12}>
          <CurrencyInput
            id="amount"
            prefix="$"
            decimalScale={2}
            allowDecimals={true}
            decimalSeparator="."
            disabled={!!piSyncer.paymentIntent}
            value={amount.toString()}
            onValueChange={onAmountChange}
          />
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup value={amount} onChange={onButtonAmountChange} exclusive>
            <ToggleButton value={20}> $20 </ToggleButton>
            <ToggleButton value={40}> $40 </ToggleButton>
            <ToggleButton value={80}> $80 </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="soc">
          <FormattedMessage id="soc" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Slider
          {...socSliderProps}
          id="soc"
          disabled={!!piSyncer.paymentIntent}
          value={soc}
          onChange={onSOCSliderChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="email">
          <FormattedMessage id="email" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="email"
          size="small"
          fullWidth
          disabled={!!piSyncer.paymentIntent}
          value={email}
          onChange={onEmailChange}
          error={emailError}
          {...(emailError ? { helperText: <FormattedMessage id="enter-valid-email" /> } : {})}
        />
      </Grid>
      <Grid item xs={12}>
        <SubmitToReaderButton
          disabled={
            preauthChargingStationResult.isLoading ||
            !!piSyncer.paymentIntent ||
            cardReader.isResetting ||
            emailError
          }
          onClick={onPreauthClick}
          cardReader={cardReader}
        />
      </Grid>
      {piSyncer.paymentIntent && (
        <Grid item xs={12}>
          <Button variant="outlined" onClick={onCancelClick}>
            <FormattedMessage id="cancel" />
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <PaymentStatus cardReader={cardReader} piSyncer={piSyncer} />
      </Grid>
      <Grid item xs={12}>
        <TestOnlyPresentPaymentButtons pi={piSyncer.paymentIntent} />
      </Grid>
    </Grid>
  );
};

const PreauthorizeFleetForm = ({ cs, refetch }: { cs: ChargingStation; refetch: () => void }) => {
  const [authorize, authorizeResult] = useFleetAuthorizeChargingStationMutation();
  const dispatch = useDispatch();

  const [fleetId, setFleetId] = useState<string>("");
  const [soc, setSOC] = useState<number>(100);

  const onSOCSliderChange = useCallback(
    (_, value) => {
      setSOC(value as number);
    },
    [setSOC]
  );
  const onAuthorizeClick = useCallback(() => {
    authorize({
      csId: cs.id,
      fleetId: fleetId,
      state_of_charge: soc,
    });
  }, [authorize, cs, fleetId, soc]);

  useEffect(() => {
    if (authorizeResult.isSuccess) {
      refetch();
    } else if (authorizeResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to preauthorize",
          message: JSON.stringify(authorizeResult.error),
        })
      );
      authorizeResult.reset();
      refetch();
    }
  }, [dispatch, authorizeResult, refetch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InputLabel htmlFor="fleet">
          <FormattedMessage id="select-fleet" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <FleetSelector fleetId={fleetId} setFleetId={setFleetId} />
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="soc">
          <FormattedMessage id="soc" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Slider {...socSliderProps} id="soc" value={soc} onChange={onSOCSliderChange} />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onAuthorizeClick} disabled={!fleetId} variant="outlined">
          <FormattedMessage id="authorize" />
        </Button>
      </Grid>
    </Grid>
  );
};

export const AuthorizeForm = (props: { cs: ChargingStation; refetch: () => void }) => {
  const [selectedTab, setSelectedTab] = useState("credit-card");
  const onTabChanged = useCallback(
    (event: SyntheticEvent, newValue: string) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  return (
    <TabContext value={selectedTab}>
      <TabList onChange={onTabChanged} variant="fullWidth" indicatorColor="secondary" centered>
        <Tab label="Credit Card" value="credit-card" />
        <Tab label="Fleet" value="fleet" />
      </TabList>
      <TabPanel value="initial">
        <Typography variant="h1">
          <FormattedMessage id="preauthorize-payment" />
        </Typography>
      </TabPanel>
      <TabPanel value="credit-card">
        <PreauthorizeCreditCardForm {...props} />
      </TabPanel>
      <TabPanel value="fleet">
        <PreauthorizeFleetForm {...props} />
      </TabPanel>
    </TabContext>
  );
};

export const PreauthorizeStatusForm = ({
  cs,
  refetch,
}: {
  cs: ChargingStation;
  refetch: () => void;
}) => {
  const [cancel, cancelResult] = useCancelChargingStationPreauthMutation();
  useEffect(() => {
    if (cancelResult.isSuccess || cancelResult.isError) {
      refetch();
    }
  }, [cancelResult, refetch]);

  const onClickCancel = (e: SyntheticEvent) => {
    e.stopPropagation();
    cancel(cs.id);
  };
  const authorization = getAuthorization(cs);
  if (!authorization) {
    return <Typography>No Preauthorization</Typography>;
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <FormattedMessage id="preauth-status" />
            </TableCell>
            <TableCell>{authorization.status}</TableCell>
          </TableRow>
          {authorization.status === "Authorized" &&
            authorization.mac_address !== "" &&
            cs.status?.status === "Occupied" &&
            cs.current_transaction === null && (
              <TableRow>
                <TableCell colSpan={2}>
                  <StartTransactionButton chargingStationId={cs.id} />
                </TableCell>
              </TableRow>
            )}
          <TableRow>
            <TableCell>
              <FormattedMessage id="preauth-type" />
            </TableCell>
            <TableCell>{authorization.type}</TableCell>
          </TableRow>
          {authorization?.mac_address && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="mac-address" />
              </TableCell>
              <TableCell>{authorization?.mac_address}</TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.state_of_charge && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="ending-soc" />
              </TableCell>
              <TableCell>{authorization?.stop_conditions?.state_of_charge}%</TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.cost_in_cents && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="amount" />
              </TableCell>
              <TableCell>
                {formatValue({
                  value: (authorization?.stop_conditions?.cost_in_cents / 100).toString(),
                  decimalSeparator: ".",
                  decimalScale: 2,
                  prefix: "$",
                })}
              </TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.energy_wh && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="energy-delivered" />
              </TableCell>
              <TableCell>{authorization?.stop_conditions?.energy_wh} Wh</TableCell>
            </TableRow>
          )}
          {authorization?.valid_until && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="valid-until" />
              </TableCell>
              <TableCell>{parseAndFormatDate(authorization?.valid_until)}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <LoadingButton
                size="small"
                loading={cancelResult.isLoading}
                variant="contained"
                color="primary"
                onClick={onClickCancel}
              >
                <FormattedMessage id="cancel-preauthorization" />
              </LoadingButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const TogglePreauthPane = ({
  cs,
  refetch,
}: {
  cs: ChargingStation;
  refetch: () => void;
}) => {
  const dispatch = useDispatch();
  const [nextState, setNextState] = useState<boolean>();
  const [setEnabled, setEnabledResult] = useSetPreauthEnabledMutation();
  const onPreauthEnabledChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNextState(e.target.checked);
    },
    [setNextState]
  );

  const onConfirmed = useCallback(() => {
    setEnabled({ csId: cs.id, enabled: nextState! });
    setNextState(undefined);
  }, [cs, nextState, setEnabled]);

  const onCancel = useCallback(() => {
    setNextState(undefined);
  }, [setNextState]);

  useEffect(() => {
    if (setEnabledResult.isSuccess || setEnabledResult.isError) {
      if (setEnabledResult.isError) {
        dispatch(
          openSnackbarError({
            title: "Failed to toggle preauth",
            message: JSON.stringify(setEnabledResult.error),
          })
        );
      }
      setEnabledResult.reset();
      refetch();
    }
  }, [dispatch, refetch, setEnabledResult]);

  return (
    <Fragment>
      <Dialog open={nextState !== undefined} onClose={onCancel}>
        <DialogTitle>Preauth for {cs.name}</DialogTitle>
        <DialogContentText>
          {nextState
            ? `Are you sure you want to enable preauthorization on ${cs.name}?`
            : `Are you sure you want to disable preauthorization on ${cs.name}?`}
        </DialogContentText>
        <DialogActions>
          <LoadingButton
            loading={setEnabledResult.isLoading}
            variant="contained"
            color="primary"
            onClick={onConfirmed}
          >
            Confirm
          </LoadingButton>
          <LoadingButton onClick={onCancel} variant="outlined" color="secondary">
            <FormattedMessage id="cancel" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <FormControlLabel
        control={
          <Switch
            checked={isPreauthEnabled(cs)}
            onChange={onPreauthEnabledChange}
            disabled={setEnabledResult.isLoading || cs.status?.status !== "Available"}
          />
        }
        label={<FormattedMessage id="preauth-enabled" />}
      />
    </Fragment>
  );
};

export const PreauthorizePane = ({ cs, refetch }: { cs: ChargingStation; refetch: () => void }) => {
  const authStatus = getAuthorization(cs)?.status;
  return (
    <Fragment>
      {(!authStatus || ["NotStarted", "Pending"].includes(authStatus)) && (
        <AuthorizeForm cs={cs} refetch={refetch} />
      )}
      {!!authStatus && ["Pending", "Authorized"].includes(authStatus) && (
        <PreauthorizeStatusForm cs={cs} refetch={refetch} />
      )}
    </Fragment>
  );
};

export const isPreauthEnabled = (cs: ChargingStation) => {
  return cs.preauth_enabled;
};

export const getAuthorization = (cs: ChargingStation) => {
  return cs.authorization;
};

export const isPreauthorized = (cs: ChargingStation) => {
  return !!getAuthorization(cs);
};
