import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Module, ModuleType } from "types/modules";
import { sec } from "utils/security";

export interface User {
  id: string;
  email: string;
  given_name: string;
  family_name: string;
  picture_url: string;
  organization: Organization;
}

export interface Session {
  id: string;
  charge_point_id: string;
  connector_id: number;
  start_time: string;
  meter_start: number;
  end_time: string;
  meter_end: number;
  end_reason: string;
}

export interface Transaction {
  id: string;
  charging_station: ChargingStationSummary;
  start_time: string;
  end_time: string;
  meter_start: number;
  meter_end: number;
  end_reason: string;
  vehicle?: Vehicle | null;
  vendor_stopped_reason?: VendorStopReason | null;
  charging_state: string;
  last_meter_values?: LastMeterValues | null;
  invoices?: Array<Invoice>;
}

export interface TransactionStopCondition {
  state_of_charge?: number;
  energy_wh?: number;
  cost_in_cents?: number;
}

export interface Invoice {
  id: string;
  total: number;
  collected?: number;
  status: string;
  line_items: Array<LineItem>;
  metadata: any;
  payment_intents: Array<PaymentIntent>;
}

export interface PaymentIntent {
  id: number;
  stripe_id: string;
  status: string;
}

export interface LineItem {
  description: string;
  rate: number;
  quantity: number;
  type: string;
  tax_info?: TaxInfo | null;
  metadata: any;
}

export interface GetAuthorizationsArg {
  csId: string;
  count: number;
  page: number;
  range: { start: string; end: string };
}

export interface PreauthChargingStationRequest {
  csId: string;

  amount_in_cents?: number;
  state_of_charge?: number;

  email_receipt_to?: string;
  reader_id?: string;
}

export interface PreauthChargingStationResponse {
  payment_intent: PaymentIntent;
  payment_intent_client_secret?: string;
}

export interface Fleet {
  id: number;
  name: string;
  organization_id: string;
}

export interface FleetAuthorizeChargingStationRequest {
  csId: string;
  fleetId: string;

  state_of_charge?: number;
}

export interface TaxInfo {
  description: string;
  rate: number;
}
export interface VendorStopReason {
  code: number;
  name: string;
  common_causes: string[];
  instructions: string[];
  is_fault: boolean;
}

export interface Envelope<T> {
  meta: Meta;
  data: T;
}

export interface Meta {
  pagination: Pagination;
  status: number;
}

export interface Pagination {
  page_count: number;
  page: number;
  count: number;
}

export interface GetSessionsArgs {
  siteId?: string | null;
  start: string;
  end: string;
  count: number;
  page: number;
  cpId?: string | null;
  orgId?: string | null;
  new?: boolean | undefined;
  time_zone?: string | null;
  module?: ModuleType;
}

export interface GetTransactionsArgs {
  siteId?: string;
  start: string;
  end: string;
  count: number;
  page: number;
  csId?: string | null;
  authorizationId?: string;
  orgId?: string | null;
  vehicleId?: string | null;
  makeId?: string | null;
  modelId?: string | null;
  vpicId?: string | null;
  expandInvoices?: boolean;
  expandVehicle?: boolean;
  module?: ModuleType;
}

export interface GetTransactionFailuresArgs {
  siteId?: string;
  start: string;
  end: string;
  csId?: string | null;
  orgId?: string | null;
  vehicleId?: string | null;
  makeId?: string | null;
  modelId?: string | null;
  vpicId?: string | null;
  includeTransactions?: boolean | null;
  timeZone: string;
}

export interface GetChargingStationFaultsArgs {
  startTime: string;
  endTime: string;
  count: number;
  page: number;
  siteId?: string;
  orgId?: string;
  csId?: string;
}

export interface GetChargingStationsUptimeArgs {
  startTime: string;
  endTime: string;
  siteId?: string;
  orgId?: string;
  csId?: string | null;
}

export interface GetTransactionArgs {
  csId: string;
  txId: string;
  expandVehicle?: boolean;
  expandInvoices?: boolean;
  module?: ModuleType;
}

export interface GetOcppMessagesArg {
  cpId: string;
  count: number;
  page: number;
  range?: { start: string; end: string } | undefined;
}

export interface ListVehiclesArgs {
  count: number;
  page: number;
  orgId?: string;
  startTime?: string;
  endTime?: string;
  module?: ModuleType;
}

export interface GetVehicleArgs {
  vehicleId: string;
  module?: ModuleType;
}

export interface GetMakesArgs {
  count: number;
  page: number;
}

export interface GetModelsArgs {
  makeId: string;
  vpicId?: string;
  count: number;
  page: number;
}

export interface GetInvoicesArgs {
  invoiceIds: string[] | undefined;
}

export interface OcppMessage {
  id: string;
  charge_point_id: string;
  origin: string;
  unique_id: string;
  created_at: string;
  action: string;
  message_type: number;
  payload: any;
}

export interface Site {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  organization: Organization;
}

export interface ChargingStation {
  id: string;
  name: string;
  created_at: string;
  manufacturer: string;
  model: string;
  serial_number: string;
  firmware_version: string;
  ocpp_version: string;
  approved: boolean;
  status?: ChargingStationStatus | null;
  current_transaction?: Transaction | null;
  pump_state?: PumpState | null;
  authorization?: ChargingStationAuthorization | null;
  preauth_enabled?: boolean;
}

export interface PumpState {
  pump_station_id: string;
  pump_id: string;
  running: boolean;
  disabled: boolean;
}

export interface PumpStation {
  id: string;
  name: string;
  site_name: string;
  site_id: string;
  powered_on: boolean;
  refresh_enabled: boolean;
  refresh_active: boolean;
  service_mode: boolean;
  version: number;
  last_updated: string;
  pump_states: Array<PumpState>;
}

export interface ChargingStationSummary {
  id: string;
  name: string;
}

export interface ChargingStationStatus {
  created_at: string;
  status: string;
  errors?: Array<VendorStopReason> | null;
  last_seen: string;
  last_used: string | null;
  disabled: boolean;
  disabled_reason?: string;
}

export interface ChargingStationAuthorization {
  id: string;
  charging_station_id: string;
  mac_address?: string;
  valid_until?: string;
  invoice_id?: string;
  invoice?: Invoice;
  stop_conditions?: TransactionStopCondition;
  finalize_reasons?: string[];

  status: "Pending" | "Authorized" | "Finalizing" | "Finalized";
  type: string;
}

export interface CreateChargingStationLogRequestsArgs {
  csId: string;
  note: string;
  create_jira_issue?:
    | {
        summary: string;
        transaction_id: string | undefined;
      }
    | undefined;
}

export interface GetChargingStationLogRequestsArgs {
  csId?: string | undefined;
  range?: { start_time: string; end_time: string } | undefined;
  include_charging_station?: boolean | undefined;
}

export interface AuditUser {
  id: string;
  email: string;
  name: string;
  organization_id: string;
}

export interface ChargingStationLogRequest {
  id: number;
  charging_station_id: string;
  charging_station_name: string | null;
  transaction_id: string | null;
  created_at: string;
  created_by: AuditUser;
  modified_at: string;
  note: string;
  status: string;
  log_link?: string | null;
  error_msg?: string | null;
  jira_issue_key?: string | null;
}

export interface ChargingStationLogRequests {
  requests: Array<ChargingStationLogRequest> | null;
}

export interface GetChargingStationLogDownloadUrlArgs {
  csId: string;
  lrId: number;
}

export interface GetChargingStationArgs {
  csId: string;
  expand_authorization?: boolean | null;
  expand_cooling?: boolean | null;
  expand_current_transaction?: boolean | null;
}

export interface LastMeterValues {
  soc: number;
  power: number;
  temp_p: number;
  temp_n: number;
  time_remaining: number;
  price_in_cents?: number;
}

export interface ChargePoint {
  id: string;
  approved: boolean;
  manufacturer: string;
  model: string;
  serial_number: string;
  firmware_version: string;
  created_at: string;
  status: ChargePointStatus | null;
}

interface ChargePointStatus {
  createdAt: string;
  status: string;
  info: string | null;
  connectorId: number;
  count?: number | null;
  page?: number | null;
}

interface EnergyUsed {
  totalEnergyUsed: number;
  totalChange: number | null;
  labels: Array<string>;
  data: Array<number>;
}

interface SessionSummary {
  totalSessions: number;
  totalSessionsPastPeriod: number;
  totalChange: number | null;
  data: Array<{ hour_of_day: number; count: number }>;
}

export interface GetMeterValuesArgs {
  cpId: string;
  count: number;
  page: number;
  txId?: string | null;
}

export interface GetTransactionChartDataArgs {
  cpId: string;
  txId: string;
  module?: ModuleType;
}

export interface MeterValue {
  id?: number | null;
  timestamp: string;
  transaction_id?: string | null;
  value: number;
  context: string | null;
  measurand: string | null;
  phase?: string | null;
  location?: string | null;
  unit?: string | null;
}

export interface TransactionChartData {
  dates: Array<string>;
  power: Array<number>;
  soc: Array<number>;
  voltage: Array<number>;
  current: Array<number>;
  pin_temps?: { positive: Array<number>; negative: Array<number> };
  cable_temps?: { positive: Array<number>; negative: Array<number> };
  temperature_n?: Array<number>;
  temperature_p?: Array<number>;
  voltage_demand: Array<number>;
  current_demand: Array<number>;
  charge_curve: Array<Array<number>>;
  power_used: Array<Array<any>>;
}

export interface Vehicle {
  id: string;
  vin?: string | null;
  make?: string | null;
  make_id?: string | null;
  model?: string | null;
  model_id?: string | null;
  year?: number | null;
  trim?: string | null;
  color?: string | null;
  description?: string | null;
  license_plate?: string | null;
  license_plate_state?: string | null;
  mac_address?: string | null;
  owner?: VehicleOwner | null;
  fleet_id?: string | null;
  created_at: string;
}

export interface Make {
  id: string;
  name?: string | null;
  vpic_id?: string | null;
}

export interface Model {
  id: string;
  year?: number | null;
  name?: string | null;
  make?: string | null;
  vpic_id?: string | null;
}

export interface VehicleOwner {
  id: string;
  display_name: string;
  type: string;
}

export interface CreateVehicleArgs {
  vin: string;
  color?: string | null;
  license_plate?: string | null;
  license_plate_state?: string | null;
  owner_id: string;
}

export interface GetOrganizationArgs {
  count: number;
  page: number;
}

export interface Organization {
  id: string;
  name: string;
  slug: string;
  auth0_id: string;
  logo_url: string;
}

export interface TransactionSummary {
  measurands: Array<{ measurand: string; min: number; max: number }>;
}

export interface TerminalReader {
  id: string;
  // https://docs.stripe.com/api/terminal/readers/object#terminal_reader_object-status
  status: "online" | "offline";
  action?: CurrentAction | null;
  payment_intent?: PaymentIntent | null;
  label: string;
}

export interface SyncPaymentIntentResponse {
  status: string;
}

export interface CurrentAction {
  type: string;
  // https://docs.stripe.com/api/terminal/readers/object#terminal_reader_object-action-status
  status: "succeeded" | "failed" | "in_progress" | "";
  failure_message: string;
  failure_code: string;
}

export interface VehicleImage {
  id: string;
  vehicle_id: string;
  charging_station_id: string;
  transaction_id: string;
  created_at: string;
  created_by: AuditUser;
  modified_at: string;
  modified_by: AuditUser;
  status: string;
  url: string;
  parsed_data?: VehicleImageParsedData;
}

export interface VehicleImageParsedData {
  text: string;
  detections: DetectionResult<Entity>[] | null;
}

export interface GetTransactionStatsArgs {
  start: string;
  end: string;
  siteId?: string | null;
  orgId?: string | null;
  vehicleId?: string | null;
  makeId?: string | null;
  modelId?: string | null;
  vpicId?: string | null;
  chargingStationId?: string | null;
  module?: ModuleType;
}

export interface TransactionStats {
  total_sessions: number;
  total_energy: number;
  average_energy: number;
  average_duration: number;
  throughput_utilization: number;
  time_utilization: number;
}

export interface TransactionFailuresData {
  date: string;
  total: number;
  failure_count: number;
  bad_error_code_count: number;
  low_power_count: number;
  short_duration_count: number;

  bad_error_code_transactions: Transaction[] | null;
  low_power_transactions: Transaction[] | null;
  short_duration_transactions: Transaction[] | null;
}

export interface ChargingStationFaultData {
  charging_station_id: string;
  charging_station_name: string;
  status: string;
  transaction_id: string | null;
  error_codes: number[];
  start_time: string;
  end_time: string;
  owner_id: string;
  site_id: string;
}

export interface ChargingStationsUptime {
  offline_duration: number;
  total_duration: number;
}

export interface DetectionResult<T = Entity> {
  type: string;
  detected: boolean;
  text: string;
  corrected_text?: string;
  messages?: Message[];
  bounds: number[][];
  entity?: T;
}

export interface Message {
  message: string;
  level: string;
}

export const isVinDetection = (
  detection: DetectionResult<Entity>
): detection is DetectionResult<VinEntity> => {
  return detection.type === "vin";
};

export const isLicensePlateDetection = (
  detection: DetectionResult<Entity>
): detection is DetectionResult<LicensePlateEntity> => {
  return detection.type === "license_plate";
};

type Entity = { type: string };

export type VinEntity = Entity & {
  type: "vin";
  vin: string;
  make: string;
  model: string;
  year: number;
};

export type LicensePlateEntity = Entity & {
  type: "license_plate";
  license_plate: string;
  state: string;
  tlc: boolean;
};

export interface FirmwareRelease {
  id: string;
  path: string;
  original_filename: string;
  versions: { name: string; version: string }[] | null;
  updates: { name: string; version: string; notes: string }[] | null;
  notes: string;

  created_at: string;
}

export interface GetFirmwareReleasesResponse {
  releases: Array<FirmwareRelease> | null;
}

export interface GetFirmwareRolloutsResponse {
  rollouts?: Array<string>;
}

export interface FirmwareUpdate {
  request_id: string;
  charging_station_id: string;
  submit_status: string;
  firmware_status: string;
  last_update: string;
}

export interface GetFirmwareRolloutResponse {
  id: string;
  download_at: string;
  install_at: string;
  updates: FirmwareUpdate[];
}

export interface ParkingSession {
  id: string;
  vehicle: Vehicle;
  phone_number: string;
  end_time: string;

  state: string;
  state_time: string;

  key_location?: string | null;
  vehicle_location?: string | null;

  created_at: string;
  created_by: AuditUser;
  modified_at: string;
  modified_by: AuditUser;
}

export interface CreateParkingSessionArgs {
  site_id: string;
  vehicle_id: string;
  phone_number: string;
  start_time: string;
  end_time: string;
}

export interface GetParkingSessionsResponse {
  sessions: Array<ParkingSession>;
}

export interface UpdateParkingSessionArgs {
  session_id: string;

  state?: string | null;
  state_time?: string | null;

  key_location?: string | null;
  vehicle_location?: string | null;
}

export interface GetParkingScheduleParams {
  site_id: string;
  start_date: string;
  end_date: string;
}

export interface GetParkingScheduleResponse {
  schedule: Array<{
    date: string;
    max_parked: number;
  }>;
}

export interface UpdateParkingScheduleOverrideParams {
  site_id: string;
  date: string;
  max_parked: number;
}

export interface DeleteParkingScheduleOverrideParams {
  site_id: string;
  date: string;
}

export interface GetParkingScheduleDefaultParams {
  site_id: string;
}

export interface GetParkingScheduleDefaultResponse {
  schedule: Array<{
    day: string;
    max_parked: number;
  }>;
}

export interface UpdateParkingScheduleDefaultParams {
  site_id: string;
  schedule: Array<{
    day: string;
    max_parked: number;
  }>;
}

export interface ListReadersParams {
  location_id?: string;
}

export interface CreateAndPromptPaymentIntentParams {
  invoiceIds: string[];
  readerId: string;
}

export interface EmployeeVerificationImage {
  id: string;
  vehicle_id?: string;
  charging_station_id?: string;
  transaction_id?: string;
  signed_url?: string;

  created_at: string;
  created_by: AuditUser;
  modified_at: string;
  modified_by: AuditUser;
}

export interface UploadEmployeeVerificationImageParams {
  files: string[];
  transaction_id?: string;
  vehicle_id?: string;
  charging_station_id?: string;
  authorization_id?: string;
}

export interface GetEmployeeVerificationImageParams {
  id: string;
}

export interface ListEmployeeVerificationImagesParams {
  transaction_id?: string;
  charging_station_id?: string;
  vehicle_id?: string;
  authorization_id?: string;
}

type HttpConnectionStartedData = {
  url: string;
  instance: string;
};

type ConnectionClosedData = {
  reason: string;
  message: string;
};

type OcppMessageData = {
  unique_id: string;
  origin: string;
  action: string;
  message_type: number;
  payload: any;
  error_code: string;
  error_description: string;
};

type OcppEventData = {
  http_connection_started: HttpConnectionStartedData;
  http_connection_ended: {};
  connection_upgraded: {};
  connection_established: {};
  connection_denied: ConnectionClosedData;
  connection_closed: ConnectionClosedData;
  closing_connection: {};
  closing_connection_failed: {};
  ocpp_message: OcppMessageData;
  ocpp_message_error: {};
};

export type OcppEventType<T extends keyof OcppEventData> = {
  id: number;
  timestamp: string;
  charging_station_id: string;
  trace_id: string;
  type: T;
  data: OcppEventData[T];
};

export type OcppEvent = {
  [K in keyof OcppEventData]: OcppEventType<K>;
}[keyof OcppEventData];

export interface UpdateVehicleParams {
  vehicle_id: string;
  fleet_id?: string | null;
  vin?: string | null;
  color?: string;
  license_plate?: string;
  license_plate_state?: string;
  owner_id?: string;
}

export interface Component {
  id: string;
  name: string;
  instance?: string | null;
  variables: Array<Variable>;
}

export interface Variable {
  id: string;
  name: string;
  instance?: string | null;
  unit?: string | null;
  data_type?: string | null;
  min_limit?: number | null;
  max_limit?: number | null;
  values_list?: Array<string> | null;
  supports_monitoring?: boolean | null;
  attribute?: VariableAttribute | null;
}

export interface VariableAttribute {
  id: string;
  type?: string | null;
  value?: string | null;
  mutability?: string | null;
  persistent?: boolean | null;
  constant?: boolean | null;
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: async (headers, api) => {
      const token = await sec.getAccessTokenSilently();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Vehicle", "VehicleImage"],
  endpoints: (builder) => ({
    getOrganizations: builder.query<Envelope<Organization[]>, GetOrganizationArgs>({
      query: (args) => {
        return "/organizations";
      },
    }),

    getVehicleOwners: builder.query<Envelope<VehicleOwner[]>, void>({
      query: () => {
        return "/owners";
      },
    }),

    getRecentSessions: builder.query<Envelope<Session[]>, GetSessionsArgs>({
      query: (args) => {
        const path = args.cpId ? `/charge-points/${args.cpId}/sessions` : "/sessions/recent";
        return `${path}?start=${args.start}&end=${args.end}&group=${args.siteId}&count=${
          args.count ?? 50
        }&page=${args.page ?? 0}`;
      },
    }),

    getEnergyUsed: builder.query<EnergyUsed, GetSessionsArgs>({
      query: (args) => {
        var url = "";
        if (args.new) {
          url += `${args.module === Module.FleetOperator ? "/fleet" : ""}/charts/energy-used`;
        } else {
          url += "/charge-points/energy-used";
        }

        const params = new URLSearchParams();
        params.set("start", args.start);
        params.set("end", args.end);
        if (args.siteId) {
          params.set("site_id", args.siteId);
        }
        if (args.cpId) {
          params.set("cp_id", args.cpId);
        }
        if (args.orgId) {
          params.set("org_id", args.orgId);
        }
        return url + `?${params.toString()}`;
      },
    }),

    getPumpStations: builder.query<{ pump_stations: PumpStation[] }, void>({
      query: () => {
        return "/pump-stations2";
      },
    }),

    getSessionSummary: builder.query<SessionSummary, GetSessionsArgs>({
      query: (args) => {
        if (args.new) {
          const params = new URLSearchParams();
          params.set("start", args.start);
          params.set("end", args.end);
          if (args.time_zone) {
            params.set("time_zone", args.time_zone);
          }
          if (args.cpId) {
            params.set("cp_id", args.cpId);
          }
          if (args.siteId) {
            params.set("site_id", args.siteId);
          }
          if (args.orgId) {
            params.set("org_id", args.orgId);
          }

          let url = "/charts/sessions";
          if (args.module === Module.FleetOperator) {
            url = "/fleet/charts/sessions";
          }
          return url + `?${params.toString()}`;
        }

        if (args.cpId) {
          return `/charge-points/${args.cpId}/sessions/summary?start=${args.start}&end=${args.end}&period=hourly&group=${args.siteId}`;
        }

        return `/sessions/summary?start=${args.start}&end=${args.end}&period=hourly&group=${args.siteId}`;
      },
    }),

    getOcppMessages: builder.query<Envelope<OcppMessage[]>, GetOcppMessagesArg>({
      query: (args) => {
        const baseUrl = `/charge-points/${args.cpId}`;
        if (args.range) {
          return `${baseUrl}/ocpp-messages-with-range?page=${args.page}&count=${args.count}&start=${args.range.start}&end=${args.range.end}`;
        }
        return `${baseUrl}/ocpp-messages?page=${args.page}&count=${args.count}`;
      },
    }),

    getSites: builder.query<Envelope<Site[]>, void>({
      query: (args) => {
        return "/admin/v1/sites";
      },
    }),

    getChargePointStatus: builder.query<number[], void>({
      query: () => {
        return "/charge-points";
      },
      transformResponse: (response: Envelope<ChargePoint[]>, meta, arg) => {
        const Available = 0,
          Charging = 1,
          SuspendedEV = 2,
          Pending = 3,
          Offline = 4;
        let series = [0, 0, 0, 0, 0];
        response.data.forEach((cp) => {
          if (cp.status?.status === "Available") {
            series[Available]++;
          } else if (cp.status?.status === "Charging") {
            series[Charging]++;
          } else if (cp.status?.status === "Offline") {
            series[Offline]++;
          } else if (cp.status?.status === "Pending") {
            series[Pending]++;
          } else if (cp.status?.status === "SuspendedEV") {
            series[SuspendedEV]++;
          }
        });
        return series;
      },
    }),

    getChargingStationsStatus: builder.query<number[], { siteId?: string | null }>({
      query: ({ siteId }) => {
        const params = new URLSearchParams();
        if (siteId) {
          params.set("site_id", siteId);
        }
        return `/charging-stations?${params.toString()}`;
      },
      transformResponse: (response: Envelope<ChargePoint[]>, meta, arg) => {
        const Available = 0,
          Occupied = 1,
          Offline = 2,
          Faulted = 3,
          Unknown = 4;
        let series = [0, 0, 0, 0, 0];
        response.data.forEach((cp) => {
          if (cp.status?.status === "Available") {
            series[Available]++;
          } else if (cp.status?.status === "Occupied") {
            series[Occupied]++;
          } else if (cp.status?.status === "Offline") {
            series[Offline]++;
          } else if (cp.status?.status === "Faulted") {
            series[Faulted]++;
          } else {
            series[Unknown]++;
          }
        });
        return series;
      },
    }),

    getChargePoints: builder.query<Envelope<ChargePoint[]>, void>({
      query: () => {
        return "/charge-points";
      },
    }),

    getChargingStations: builder.query<
      Envelope<ChargingStation[]>,
      {
        siteId?: string | null;
        expand_authorization?: boolean;
        expand_cooling?: boolean;
        expand_current_transaction?: boolean;
      } | void
    >({
      query: (args) => {
        const params = new URLSearchParams();
        if (args?.expand_authorization) {
          params.set("expand_authorization", "true");
        }
        if (args?.expand_cooling) {
          params.set("expand_cooling", "true");
        }
        if (args?.expand_current_transaction) {
          params.set("expand_current_transaction", "true");
        }
        if (args?.siteId) {
          params.set("site_id", args.siteId);
        }
        return `/charging-stations?${params.toString()}`;
      },
    }),

    getChargingStation: builder.query<Envelope<ChargingStation>, GetChargingStationArgs>({
      query: (args) => {
        var url = `/charging-stations/${args.csId}`;
        if (args) {
          const params = new URLSearchParams();
          if (args?.expand_authorization) {
            params.set("expand_authorization", "true");
          }
          if (args?.expand_cooling) {
            params.set("expand_cooling", "true");
          }
          if (args?.expand_current_transaction) {
            params.set("expand_current_transaction", "true");
          }
          url += "?" + params.toString();
        }
        return url;
      },
    }),

    authorizeChargingStation: builder.mutation<void, { csId: string }>({
      query: (args) => {
        return {
          url: `/charging-stations/${args.csId}/authorize`,
          method: "POST",
        };
      },
    }),

    getAuthorizations: builder.query<
      Envelope<ChargingStationAuthorization[]>,
      GetAuthorizationsArg
    >({
      query: (args) => {
        const params = new URLSearchParams();
        if (args.page) {
          params.set("page", `${args.page}`);
        }
        if (args.count) {
          params.set("count", `${args.count}`);
        }
        if (args.range.start) {
          params.set("start", args.range.start);
        }
        if (args.range.end) {
          params.set("end", args.range.end);
        }
        params.set("expand_invoices", "true");
        return `/charging-stations/${args.csId}/authorizations?${params.toString()}`;
      },
    }),

    preauthChargingStation: builder.mutation<
      Envelope<PreauthChargingStationResponse>,
      PreauthChargingStationRequest
    >({
      query: (arg) => {
        return {
          url: `/charging-stations/${arg.csId}/preauth`,
          method: "POST",
          body: {
            amount_in_cents: arg.amount_in_cents,
            state_of_charge: arg.state_of_charge,
            email_receipt_to: arg.email_receipt_to,
            reader_id: arg.reader_id,
          },
        };
      },
    }),

    listFleets: builder.query<Envelope<Fleet[]>, string | null | void>({
      query: (organization_id) => {
        if (organization_id) {
          return { url: `/fleets?organization_id=${organization_id}` };
        }
        return { url: "/fleets" };
      },
    }),

    fleetAuthorizeChargingStation: builder.mutation<void, FleetAuthorizeChargingStationRequest>({
      query: (args) => {
        return {
          url: `/charging-stations/${args.csId}/fleet-authorize`,
          method: "POST",
          body: {
            fleet_id: args.fleetId,
            state_of_charge: args.state_of_charge,
          },
        };
      },
    }),

    cancelChargingStationPreauth: builder.mutation<void, string>({
      query: (csId) => {
        return {
          url: `/charging-stations/${csId}/cancel-preauth`,
          method: "POST",
        };
      },
    }),

    setPreauthEnabled: builder.mutation<void, { csId: string; enabled: boolean }>({
      query: (args) => {
        return {
          url: `/charging-stations/${args.csId}/set-preauth-enabled`,
          method: "POST",
          body: {
            enabled: args.enabled,
          },
        };
      },
    }),

    createChargingStationLogRequest: builder.mutation<void, CreateChargingStationLogRequestsArgs>({
      query: (args) => {
        const r = {
          url: `/charging-station/${args.csId}/create-log-request`,
          method: "POST",
          body: {
            note: args.note,
            create_jira_issue: args.create_jira_issue,
          },
        };
        return r;
      },
    }),

    getChargingStationLogRequests: builder.query<
      Envelope<ChargingStationLogRequests>,
      GetChargingStationLogRequestsArgs
    >({
      query: (args) => {
        var query_args: string[] = [];
        if (args.range?.start_time) {
          query_args.push(`start=${args.range.start_time}`);
        }
        if (args.range?.end_time) {
          query_args.push(`end=${args.range.end_time}`);
        }
        if (args.include_charging_station) {
          query_args.push("include_charging_station=true");
        }
        var query_string = "";
        if (query_args.length > 0) {
          query_string = "?" + query_args.join("&");
        }
        if (args.csId) {
          return `/charging-stations/${args.csId}/log-requests${query_string}`;
        }
        return `/log-requests${query_string}`;
      },
    }),

    getChargingStationLogDownloadUrl: builder.query<
      Envelope<{ url: string }>,
      GetChargingStationLogDownloadUrlArgs
    >({
      query: (args) => {
        return `charging-stations/${args.csId}/get-signed-log-url/${args.lrId}`;
      },
    }),

    getTransactionSummary: builder.query<
      Envelope<TransactionSummary>,
      { csId: string; txId: string }
    >({
      query: (args) => {
        return `/charging-stations/${args.csId}/transactions/${args.txId}/summary`;
      },
    }),

    getTransactions: builder.query<Envelope<Transaction[]>, GetTransactionsArgs>({
      query: (args) => {
        const params = new URLSearchParams();
        params.set("start", args.start);
        params.set("end", args.end);
        params.set("count", `${args.count ?? 50}`);
        params.set("page", `${args.page ?? 0}`);
        if (args.siteId) {
          params.set("site_id", args.siteId);
        }
        if (args.orgId) {
          params.set("org_id", args.orgId);
        }
        if (args.csId) {
          params.set("cp_id", args.csId);
        }
        if (args.authorizationId) {
          params.set("authorization_id", args.authorizationId);
        }
        if (args.vehicleId) {
          params.set("vehicle_id", args.vehicleId);
        }
        if (args.makeId) {
          params.set("make_id", args.makeId);
        }
        if (args.modelId) {
          params.set("model_id", args.modelId);
        }
        if (args.expandInvoices) {
          params.set("expand_invoices", "true");
        }
        if (args.expandVehicle) {
          params.set("expand_vehicle", "true");
        }
        var url = "/transactions";
        if (args.module === Module.FleetOperator) {
          url = "/fleet/transactions";
        }
        return url + `?${params.toString()}`;
      },
    }),

    getTransaction: builder.query<Envelope<Transaction>, GetTransactionArgs>({
      query: (args) => {
        const params = new URLSearchParams();
        if (args.expandInvoices) {
          params.set("expand_invoices", "true");
        }
        if (args.expandVehicle) {
          params.set("expand_vehicle", "true");
        }
        var url = "";
        if (args.module === Module.FleetOperator) {
          url = "/fleet";
        }
        return `${url}/charging-stations/${args.csId}/transactions/${
          args.txId
        }?${params.toString()}`;
      },
    }),

    getFleetCurrentTransactions: builder.query<Envelope<Transaction[]>, void>({
      query: () => {
        return "/fleet/current_transactions";
      },
    }),

    getMeterValues: builder.query<Envelope<MeterValue[]>, GetMeterValuesArgs>({
      query: (args) => {
        var baseUrl = `/charging-stations/${args.cpId}`;
        if (args.txId) {
          baseUrl = `${baseUrl}/transactions/${args.txId}`;
        }
        return `${baseUrl}/meter-values?count=${args.count ?? 50}&page=${args.page ?? 0}`;
      },
    }),

    getTransactionChartData: builder.query<TransactionChartData, GetTransactionChartDataArgs>({
      query: (args) => {
        return `${args.module === Module.FleetOperator ? "/fleet" : ""}/charging-stations/${
          args.cpId
        }/transactions/${args.txId}/chart-data`;
      },
    }),

    listVehicles: builder.query<Envelope<Vehicle[]>, ListVehiclesArgs>({
      query: (args) => {
        const params = new URLSearchParams();
        if (args) {
          if (args?.count) {
            params.set("count", `${args.count}`);
          }
          if (args?.page) {
            params.set("page", `${args.page}`);
          }
          if (args?.orgId) {
            params.set("org_id", args.orgId);
          }
          if (args?.startTime) {
            params.set("start", args.startTime);
          }
          if (args?.endTime) {
            params.set("end", args.endTime);
          }
        }
        var url = "/vehicles";
        if (args.module === Module.FleetOperator) {
          url = "/fleet/vehicles";
        }
        return url + `?${params.toString()}`;
      },
      providesTags: (result, error, arg) =>
        result
          ? [...result.data.map(({ id }) => ({ type: "Vehicle" as const, id })), "Vehicle"]
          : ["Vehicle"],
    }),

    getVehicle: builder.query<Envelope<Vehicle>, string | GetVehicleArgs>({
      query: (args) => {
        if (typeof args === "string") {
          return `/vehicles/${args}`;
        }
        var url = "/vehicles";
        if (args.module === Module.FleetOperator) {
          url = "/fleet/vehicles";
        }
        return url + `/${args.vehicleId}`;
      },
      providesTags: (result, error, id) => [
        { type: "Vehicle", id: typeof id === "string" ? id : id.vehicleId },
      ],
    }),

    getMakes: builder.query<Envelope<Make[]>, GetMakesArgs>({
      query: (args) => {
        return `vehicle-makes?count=${args.count}&page=${args.page}`;
      },
    }),

    getMake: builder.query<Envelope<Make>, string>({
      query: (makeId) => {
        return `/vehicle-makes/${makeId}`;
      },
    }),

    getModels: builder.query<Envelope<Model[]>, GetModelsArgs>({
      query: (args) => {
        const params = new URLSearchParams();
        params.set("count", `${args.count}`);
        params.set("page", `${args.page}`);
        if (args.vpicId) {
          params.set("vpic_id", args.vpicId);
        }
        return `vehicle-makes/${args.makeId}/vehicle-models?${params.toString()}`;
      },
    }),

    getModel: builder.query<Envelope<Model>, string>({
      query: (modelId) => {
        return `vehicle-models/${modelId}`;
      },
    }),

    uploadVehicleImage: builder.mutation<Envelope<VehicleImage[]>, FormData>({
      query: (data: FormData) => {
        return {
          url: `/vehicle-image`,
          method: "POST",
          body: data,
        };
      },
    }),

    getVehicleImageResult: builder.query<Envelope<VehicleImage>, string>({
      query: (id) => {
        return `/vehicle-images/${id}`;
      },
    }),

    getVehicleImages: builder.query<Envelope<VehicleImage[]>, void>({
      query: () => {
        return `/vehicle-images`;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "VehicleImage" as const, id })),
              "VehicleImage",
            ]
          : ["VehicleImage"],
    }),

    getVehicleImage: builder.query<{ url: string }, string>({
      query: (imageId) => ({
        url: `/vehicle-images/${imageId}/image`,
        async responseHandler(response) {
          const blob = await response.blob();
          return {
            url: URL.createObjectURL(blob),
          };
        },
      }),
      providesTags: (result, error, id) => [{ type: "VehicleImage", id }],
    }),

    uploadEmployeeVerificationImage: builder.mutation<
      Envelope<EmployeeVerificationImage[]>,
      UploadEmployeeVerificationImageParams
    >({
      query: (args) => ({
        url: `/employee-verification-images`,
        method: "POST",
        body: args,
      }),
    }),

    getEmployeeVerificationImage: builder.query<
      Envelope<EmployeeVerificationImage>,
      GetEmployeeVerificationImageParams
    >({
      query: (args) => ({
        url: `/employee-verification-images/${args.id}`,
      }),
    }),

    listEmployeeVerificationImages: builder.query<
      Envelope<EmployeeVerificationImage[]>,
      ListEmployeeVerificationImagesParams
    >({
      query: (args) => {
        var url = `/employee-verification-images`;
        const params = new URLSearchParams();
        if (args.charging_station_id) {
          params.set("charging_station_id", args.charging_station_id);
        }
        if (args.transaction_id) {
          params.set("transaction_id", args.transaction_id);
        }
        if (args.vehicle_id) {
          params.set("vehicle_id", args.vehicle_id);
        }
        url += `?${params.toString()}`;
        return url;
      },
    }),

    assignVehicle: builder.mutation<Envelope<Transaction>, { vehicleId: string; txId: string }>({
      query: ({ vehicleId, txId }) => ({
        url: `/transactions/${txId}/assign-vehicle`,
        method: "POST",
        body: { vehicle_id: vehicleId },
      }),
    }),

    confirmVehicleImage: builder.mutation<
      Envelope<Vehicle>,
      {
        vehicleImageId: string;
        data: {
          vin: string;
          license_plate?: string | null;
          license_plate_state?: string | null;
          organization_id?: string | null;
        };
      }
    >({
      query: ({ vehicleImageId, data }) => ({
        url: `/vehicle-images/${vehicleImageId}/confirm`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "VehicleImage", id: arg.vehicleImageId }],
    }),

    dismissVehicleImage: builder.mutation<void, string>({
      query: (arg) => ({
        url: `/vehicle-images/${arg}/dismiss`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "VehicleImage", id: arg }],
    }),

    runVinCheck: builder.mutation<Envelope<DetectionResult<VinEntity>>, { vin: string }>({
      query: ({ vin }) => ({
        url: `/check-vin/${vin}`,
      }),
    }),

    setChargingProfile: builder.mutation<
      Envelope<Transaction>,
      { csId: string; txId: string; limit: number }
    >({
      query: ({ csId, txId, limit }) => ({
        url: `/charging-station/${csId}/set-charging-profile-default`,
        method: "POST",
        body: {
          profile_id: 10,
          stack_level: 10,
          start_period: 0,
          duration: 0,
          transaction_id: txId,
          limit: limit,
        },
      }),
    }),

    clearChargingProfile: builder.mutation<Envelope<Transaction>, { csId: string }>({
      query: ({ csId }) => ({
        url: `/charging-station/${csId}/clear-charging-profile/10`,
        method: "POST",
      }),
    }),

    createVehicle: builder.mutation<Envelope<Vehicle>, CreateVehicleArgs>({
      query: (args) => ({
        url: `/vehicles/${args.vin}`,
        method: "POST",
        body: args,
      }),
    }),

    stopTransaction: builder.mutation<void, { csId: string; txId: string }>({
      query: ({ csId, txId }) => ({
        url: `/charging-station/${csId}/stop-transaction/${txId}`,
        method: "POST",
      }),
    }),

    unlockConnector: builder.mutation<void, { csId: string; evseId: number; connectorId: number }>({
      query: ({ csId, evseId, connectorId }) => ({
        url: `/charging-station/${csId}/unlock-connector/${evseId}/${connectorId}`,
        method: "POST",
      }),
    }),

    getModules: builder.query<Envelope<string[]>, void>({
      query: () => ({
        url: "/modules",
      }),
    }),

    getOccupiedChargingStations: builder.query<Envelope<ChargingStation[]>, void>({
      query: () => ({
        url: "/occupied-charging-stations",
      }),
    }),

    getInvoice: builder.query<Envelope<Invoice>, string>({
      query: (invoiceId) => ({
        url: `/billing/invoices/${invoiceId}`,
      }),
    }),

    getInvoices: builder.query<Envelope<Invoice[]>, GetInvoicesArgs>({
      query: (args) => ({
        url: `/billing/invoices?invoice_ids=${
          args.invoiceIds && args.invoiceIds.join("&invoice_ids=")
        }`,
      }),
    }),

    listReaders: builder.query<Envelope<TerminalReader[]>, ListReadersParams>({
      query: (args) => ({
        url: `/billing/readers?${args.location_id ? `location_id=${args.location_id}` : ""}`,
      }),
    }),

    getCardReaderStatus: builder.query<Envelope<TerminalReader>, string>({
      query: (readerId) => ({
        url: `/billing/readers/${readerId}`,
      }),
    }),

    resetCardReader: builder.mutation<Envelope<TerminalReader>, string>({
      query: (readerId) => ({
        url: `/billing/readers/${readerId}/reset`,
        method: "POST",
      }),
    }),

    syncPaymentIntent: builder.mutation<Envelope<SyncPaymentIntentResponse>, string>({
      query: (paymentIntentId) => ({
        url: `/billing/payment-intents/${paymentIntentId}/sync`,
        method: "POST",
      }),
    }),

    createAndPromptPaymentIntent: builder.mutation<
      Envelope<TerminalReader>,
      CreateAndPromptPaymentIntentParams
    >({
      query: (args) => ({
        url: "/billing/payment-intents",
        method: "POST",
        body: {
          invoice_ids: args.invoiceIds,
          reader_id: args.readerId,
        },
      }),
    }),

    testOnlyPresentPaymentMethod: builder.mutation<
      Envelope<void>,
      "declineCharge" | "acceptCharge"
    >({
      query: (arg) => ({
        url:
          "/billing/test-only-present-payment-method" +
          (arg === "declineCharge" ? "?decline_charge=true" : ""),
        method: "POST",
      }),
    }),

    sendEmailReceipt: builder.mutation<Envelope<void>, { paymentIntentId: string; email: string }>({
      query: (args) => ({
        url: `/billing/payment-intents/${args.paymentIntentId}/email-receipt`,
        method: "POST",
        body: {
          email: args.email,
        },
      }),
    }),

    getMe: builder.query<Envelope<User>, void>({
      query: () => ({
        url: "/me",
      }),
    }),

    getSalesTax: builder.query<
      Envelope<{ gross_sales: number; sales_tax_collected: number }>,
      { start_time: string; end_time: string }
    >({
      query: (args) => ({
        url: `/get-sales-tax?start_time=${args.start_time}&end_time=${args.end_time}`,
      }),
    }),

    getTransactionStats: builder.query<Envelope<TransactionStats>, GetTransactionStatsArgs>({
      query: (args) => {
        const params = new URLSearchParams();
        params.set("start", args.start);
        params.set("end", args.end);
        if (args.orgId) {
          params.set("org_id", args.orgId);
        }
        if (args.vehicleId) {
          params.set("vehicle_id", args.vehicleId);
        }
        if (args.makeId) {
          params.set("make_id", args.makeId);
        }
        if (args.siteId) {
          params.set("site_id", args.siteId);
        }
        if (args.modelId) {
          params.set("model_id", args.modelId);
        }
        if (args.vpicId) {
          params.set("vpic_id", args.vpicId);
        }
        if (args.chargingStationId) {
          params.set("cp_id", args.chargingStationId);
        }
        var url = "/transactions/stats";
        if (args.module === Module.FleetOperator) {
          url = "/fleet/transactions/stats";
        }
        return url + `?${params.toString()}`;
      },
    }),

    getTransactionFailures: builder.query<
      Envelope<TransactionFailuresData[]>,
      GetTransactionFailuresArgs
    >({
      query: (args) => {
        const params = new URLSearchParams();
        params.set("start", args.start);
        params.set("end", args.end);
        if (args.siteId) {
          params.set("site_id", args.siteId);
        }
        if (args.orgId) {
          params.set("org_id", args.orgId);
        }
        if (args.csId) {
          params.set("cp_id", args.csId);
        }
        if (args.vehicleId) {
          params.set("vehicle_id", args.vehicleId);
        }
        if (args.makeId) {
          params.set("make_id", args.makeId);
        }
        if (args.modelId) {
          params.set("model_id", args.modelId);
        }
        if (args.vpicId) {
          params.set("vpic_id", args.vpicId);
        }
        if (args.includeTransactions) {
          params.set("include_transactions", "true");
        }
        if (args.timeZone) {
          params.set("time_zone", args.timeZone);
        }
        return `/transactions/failures?${params.toString()}`;
      },
    }),

    getChargingStationFaults: builder.query<
      Envelope<ChargingStationFaultData[]>,
      GetChargingStationFaultsArgs
    >({
      query: (args) => {
        const params = new URLSearchParams();
        params.set("start", args.startTime);
        params.set("end", args.endTime);
        params.set("count", `${args.count}`);
        params.set("page", `${args.page}`);
        if (args.siteId) {
          params.set("site_id", args.siteId);
        }
        if (args.orgId) {
          params.set("org_id", args.orgId);
        }
        if (args.csId) {
          params.set("cp_id", args.csId);
        }
        return `/charging-station-faults?${params.toString()}`;
      },
    }),

    getChargingStationsUptime: builder.query<
      Envelope<ChargingStationsUptime>,
      GetChargingStationsUptimeArgs
    >({
      query: (args) => {
        const params = new URLSearchParams();
        params.set("start", args.startTime);
        params.set("end", args.endTime);
        if (args.siteId) {
          params.set("site_id", args.siteId);
        }
        if (args.orgId) {
          params.set("org_id", args.orgId);
        }
        if (args.csId) {
          params.set("cp_id", args.csId);
        }
        return `/charging-stations-uptime?${params.toString()}`;
      },
    }),

    createParkingSession: builder.mutation<Envelope<ParkingSession>, CreateParkingSessionArgs>({
      query: (args) => {
        return {
          url: `/parking/sessions`,
          method: "POST",
          body: args,
        };
      },
    }),

    getParkingSession: builder.query<
      Envelope<ParkingSession>,
      {
        session_id: string;
      }
    >({
      query: (args) => ({
        url: `/parking/sessions/${args.session_id}`,
      }),
    }),

    getParkingSessions: builder.query<
      Envelope<GetParkingSessionsResponse>,
      {
        site_id: string | null | undefined;
        count: number | null | undefined;
        page: number | null | undefined;
      }
    >({
      query: (args) => ({
        url: `/parking/sessions?site_id=${args.site_id ?? ""}&count=${args.count ?? 50}&page=${
          args.page ?? 0
        }`,
      }),
    }),

    updateParkingSession: builder.mutation<Envelope<ParkingSession>, UpdateParkingSessionArgs>({
      query: (args) => ({
        url: `/parking/sessions/${args.session_id}`,
        method: "PATCH",
        body: {
          state: args.state,
          state_time: args.state_time,
          key_location: args.key_location,
          vehicle_location: args.vehicle_location,
        },
      }),
    }),

    getParkingSchedule: builder.query<
      Envelope<GetParkingScheduleResponse>,
      GetParkingScheduleParams
    >({
      query: (args) => ({
        url: `/parking/sites/${args.site_id}/schedule?start_date=${args.start_date}&end_date=${args.end_date}`,
      }),
    }),

    getParkingScheduleOverride: builder.query<
      Envelope<GetParkingScheduleResponse>,
      GetParkingScheduleParams
    >({
      query: (args) => ({
        url: `/parking/sites/${args.site_id}/schedule-overrides?start_date=${args.start_date}&end_date=${args.end_date}`,
      }),
    }),

    updateParkingScheduleOverride: builder.mutation<
      Envelope<void>,
      UpdateParkingScheduleOverrideParams
    >({
      query: (args) => ({
        url: `/parking/sites/${args.site_id}/schedule-overrides`,
        method: "PATCH",
        body: args,
      }),
    }),

    deleteParkingScheduleOverride: builder.mutation<
      Envelope<void>,
      DeleteParkingScheduleOverrideParams
    >({
      query: (args) => ({
        url: `/parking/sites/${args.site_id}/schedule-overrides?date=${args.date}`,
        method: "DELETE",
      }),
    }),

    getParkingScheduleDefault: builder.query<
      Envelope<GetParkingScheduleDefaultResponse>,
      GetParkingScheduleDefaultParams
    >({
      query: (args) => ({
        url: `/parking/sites/${args.site_id}/schedule-defaults`,
      }),
    }),

    updateParkingScheduleDefault: builder.mutation<
      Envelope<void>,
      UpdateParkingScheduleDefaultParams
    >({
      query: (args) => ({
        url: `/parking/sites/${args.site_id}/schedule-defaults`,
        method: "PATCH",
        body: args,
      }),
    }),

    createFirmwareRelease: builder.mutation<
      Envelope<void>,
      {
        release_name: string;
        filename: string;
        data: ArrayBuffer;
      }
    >({
      query: (args) => {
        const buffer = Buffer.from(args.data);
        return {
          url: `/firmware/releases/${args.release_name}`,
          method: "PUT",
          body: {
            data: buffer.toString("base64"),
            filename: args.filename,
          },
        };
      },
    }),

    getFirmwareReleases: builder.query<Envelope<GetFirmwareReleasesResponse>, void>({
      query: () => {
        return `/firmware/releases`;
      },
    }),

    createFirmwareRollout: builder.mutation<
      Envelope<void>,
      {
        releaseName: string;
        rolloutName: string;
        downloadAt: string;
        installAt: string;
        chargingStationIds: string[];
      }
    >({
      query: (args) => {
        return {
          url: `/firmware/releases/${args.releaseName}/rollouts/${args.rolloutName}`,
          method: "PUT",
          body: {
            download_at: args.downloadAt,
            install_at: args.installAt,
            charging_station_ids: args.chargingStationIds,
          },
        };
      },
    }),

    getFirmwareRollouts: builder.query<
      Envelope<GetFirmwareRolloutsResponse>,
      { release_name: string }
    >({
      query: (args) => {
        return `/firmware/releases/${args.release_name}/rollouts`;
      },
    }),

    getFirmwareRollout: builder.query<
      Envelope<GetFirmwareRolloutResponse>,
      {
        release_name: string;
        rollout_name: string;
      }
    >({
      query: (args) => {
        return `/firmware/releases/${args.release_name}/rollouts/${args.rollout_name}`;
      },
    }),

    getOcppConnections: builder.query<
      Envelope<OcppEvent[]>,
      { csId: string; range: { start: string; end: string } }
    >({
      query: (args) => {
        const params = new URLSearchParams();
        params.set("start", args.range.start);
        params.set("end", args.range.end);
        return `/charging-stations/${args.csId}/ocpp-connections?${params.toString()}`;
      },
    }),

    getOcppEvents: builder.query<
      Envelope<OcppEvent[]>,
      { csId: string; traceId?: string; range: { start: string; end: string } }
    >({
      query: (args) => {
        const params = new URLSearchParams();
        params.set("start", args.range.start);
        params.set("end", args.range.end);
        if (args.traceId) {
          params.set("trace_id", args.traceId);
        }
        return `/charging-stations/${args.csId}/ocpp-events?${params.toString()}`;
      },
    }),

    updateVehicle: builder.mutation<Envelope<Vehicle>, UpdateVehicleParams>({
      query: (args) => {
        return {
          url: `/vehicles/${args.vehicle_id}`,
          method: "PATCH",
          body: args,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Vehicle", id: arg.vehicle_id }],
    }),

    getComponentConfig: builder.query<Envelope<Component[]>, string>({
      query: (csId) => {
        return `/charging-stations/${csId}/variables`;
      },
    }),
  }),
});

export const {
  useGetRecentSessionsQuery,
  useGetSitesQuery,
  useGetChargePointStatusQuery,
  useGetEnergyUsedQuery,
  useGetSessionSummaryQuery,
  useGetChargePointsQuery,
  useGetChargingStationsQuery,
  useAuthorizeChargingStationMutation,
  useGetAuthorizationsQuery,
  usePreauthChargingStationMutation,
  useListFleetsQuery,
  useFleetAuthorizeChargingStationMutation,
  useCancelChargingStationPreauthMutation,
  useSetPreauthEnabledMutation,
  useGetOcppMessagesQuery,
  useGetTransactionsQuery,
  useGetTransactionQuery,
  useGetFleetCurrentTransactionsQuery,
  useGetMeterValuesQuery,
  useGetChargingStationQuery,
  useCreateChargingStationLogRequestMutation,
  useGetChargingStationLogRequestsQuery,
  useLazyGetChargingStationLogDownloadUrlQuery,
  useGetTransactionChartDataQuery,
  useListVehiclesQuery,
  useGetVehicleQuery,
  useLazyGetVehicleQuery,
  useGetMakesQuery,
  useGetMakeQuery,
  useGetModelsQuery,
  useGetModelQuery,
  useAssignVehicleMutation,
  useSetChargingProfileMutation,
  useClearChargingProfileMutation,
  useCreateVehicleMutation,
  useStopTransactionMutation,
  useUnlockConnectorMutation,
  useGetOrganizationsQuery,
  useGetVehicleOwnersQuery,
  useGetModulesQuery,
  useGetOccupiedChargingStationsQuery,
  useGetChargingStationsStatusQuery,
  useGetTransactionSummaryQuery,
  useGetInvoiceQuery,
  useGetInvoicesQuery,
  useCreateAndPromptPaymentIntentMutation,
  useGetCardReaderStatusQuery,
  useResetCardReaderMutation,
  useListReadersQuery,
  useSyncPaymentIntentMutation,
  useTestOnlyPresentPaymentMethodMutation,
  useGetMeQuery,
  useGetPumpStationsQuery,
  useSendEmailReceiptMutation,
  useGetSalesTaxQuery,
  useUploadVehicleImageMutation,
  useGetVehicleImageResultQuery,
  useGetTransactionStatsQuery,
  useGetTransactionFailuresQuery,
  useGetChargingStationFaultsQuery,
  useGetChargingStationsUptimeQuery,
  useGetVehicleImagesQuery,
  useCreateParkingSessionMutation,
  useGetParkingSessionQuery,
  useGetParkingSessionsQuery,
  useUpdateParkingSessionMutation,
  useGetParkingScheduleQuery,
  useGetParkingScheduleOverrideQuery,
  useUpdateParkingScheduleOverrideMutation,
  useDeleteParkingScheduleOverrideMutation,
  useGetParkingScheduleDefaultQuery,
  useUpdateParkingScheduleDefaultMutation,
  useGetVehicleImageQuery,
  useConfirmVehicleImageMutation,
  useRunVinCheckMutation,
  useDismissVehicleImageMutation,
  useCreateFirmwareReleaseMutation,
  useCreateFirmwareRolloutMutation,
  useGetFirmwareReleasesQuery,
  useGetFirmwareRolloutsQuery,
  useGetFirmwareRolloutQuery,
  useUploadEmployeeVerificationImageMutation,
  useGetEmployeeVerificationImageQuery,
  useListEmployeeVerificationImagesQuery,
  useGetOcppConnectionsQuery,
  useGetOcppEventsQuery,
  useUpdateVehicleMutation,
  useGetComponentConfigQuery,
} = apiSlice;
